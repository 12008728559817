import { useState } from 'react'
import { URL_API } from '../../../values/AppConstants'
import { FiEdit } from 'react-icons/fi'
import { MdDelete } from 'react-icons/md'
import failedImage from '../../../assets/no_image_small.png'
import Loader from '../../loader/Loader'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Delete } from '../../../networking/Delete'
import FullImage from '../../fullimage/FullImage'

var controller = new AbortController()
var searchTimer = null
const ViewProduct = () => {
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const { login } = useSelector(state => state.login)
    const [showImage, setShowImage] = useState({ show: false, url: '' })

    const handleQueryChange = (event) => {
        const val = event.target.value
        if (searchTimer != null) {
            clearTimeout(searchTimer)
            searchTimer = null
        }
        searchTimer = setTimeout(() => {
            resetData()
            getList(val)
        }, 500)
    }

    const resetData = () => {
        controller.abort()
        // console.log('aborted')
        controller = new AbortController()
        setData(null)
    }

    const getList = async (query) => {
        if (query == '') {
            return
        }
        setIsLoading(true)
        const url = URL_API.search + '?search=' + query
        fetch(url, { method: 'GET', signal: controller.signal })
            .then(res => {
                if (!res.ok) {
                    throw Error('could not fetch the data for that resource')
                }
                return res.json()
            })
            .then((res) => {
                setIsLoading(false)
                // console.log(res);
                const dataObj = res.data
                if (res.data.length === 0) {
                    return setData(null)
                }
                setData(dataObj)
            })
            .catch(err => {
                // console.log(err);
                setIsLoading(false)
            })
    }

    const handleDelete = async (id) => {
        setIsLoading(true)
        const postData = {
            url: URL_API.deleteProduct + id,
            token: login.token
        }
        Delete(postData, responseDelete)
    }

    const responseDelete = (dataObj, error) => {
        setIsLoading(false)
        if (error) {
            alert(error)
            return
        }
        const filter = data.filter(item => item.id !== dataObj.id)
        setData(filter)
        alert(dataObj.message)
    }
    const TableRow = ({ item }) => {
        const imageLoadFailed = ({ target }) => {
            target.onerror = null; // prevents looping
            target.src = failedImage
        }
        const openImage = () => {
            setShowImage({
                show: true,
                url: URL_API.images_base + item.photo
            })
        }

        return (
            <tr className='table-row'>
                <td className="table-item">
                    <div>
                        <img className="table-image" src={URL_API.images_base + item.photo} alt="product"
                            onError={imageLoadFailed} onClick={openImage}/>
                        <p className="item-name">{item.name}</p>
                    </div>
                </td>
                <td>Rs. {item.price}</td>
                <td>{item.category}</td>
                <td>{item.sub_category}</td>
                <td >
                    <div className='table-action'>
                        <Link className='table-action-button' to={`/edit-product/${item.id}`}>
                            <FiEdit size={20} />
                        </Link>
                        <button className='table-action-button' onClick={() => handleDelete(item.id)}>
                            <MdDelete style={{ fill: 'red' }} size={20} />
                        </button>
                    </div>
                </td>
            </tr>
        )
    }
    return (
        <div className="container">
            <Loader show={isLoading}/>
            <FullImage show={showImage.show} url={showImage.url} close={() => setShowImage({ show: false })} />
            <div className='page-container'>
                <h1 className="page-title">View Products</h1>
                <div className='page-inner-container'>
                    <input type="text" placeholder='Search product to view'
                        className='search-input' onChange={handleQueryChange} />
                    {data &&
                        // <div>
                        <table>
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Price</th>
                                    <th>Category</th>
                                    <th>Sub Category</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, i) => <TableRow item={item} key={item.id} />)}
                            </tbody>
                        </table>
                        // </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default ViewProduct;