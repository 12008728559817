import { useEffect, useState } from 'react';
import { URL_API } from '../../../values/AppConstants';
import Loader from '../../loader/Loader';
import { Post } from '../../../networking/Post';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const EditCustomer = () => {
    const { id } = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [postalCode, setPostalCode] = useState('')
    // for uploading new file
    const { login } = useSelector(state => state.login)


    useEffect(() => {
        const url = URL_API.getSingleCustomer + id
        const mHeader = new Headers()
        mHeader.append('Authorization', 'Bearer ' + login.token)
        fetch(url, {
            method: 'GET',
            headers: mHeader,
        })
            .then(res => {
                // console.log(res.json());
                if (!res.ok) {
                    throw Error('could not fetch the data for that resource')
                }
                return res.json()
            })
            .then(res => {
                setIsLoading(false)
                const data = res.data
                if (data) {
                    setName(data.full_name)
                    setPhone(data.phone_number.toString())
                    setAddress(data.address)
                    setCity(data.city)
                    setPostalCode(data.postal_code)
                }
            })
            .catch(e => {
                setIsLoading(false)
                alert(e.message)
            })
    }, [])

    const handleUpdate = async () => {
        if (name === '' || phone === '') {
            return alert('Please enter required fields')
        }
        setIsLoading(true)
        const url = URL_API.updateCustomers + id
        const formData = new FormData()
        formData.append('full_name', name)
        formData.append('phone_number', phone)
        formData.append('address', address)
        formData.append('city', city)
        formData.append('postal_code', postalCode)
        const postData = {
            url: url,
            token: login.token,
            body: formData
        }
        Post(postData, response)
    }

    const response = (dataObj, error) => {
        setIsLoading(false)
        if (error) {
            alert(error)
            return
        }
        alert(dataObj.message)
    }

    return (
        <div className="container">
            <Loader show={isLoading} />
            <div className='page-container'>
                <h1 className="page-title">Edit Customer</h1>
                {/* form */}
                <div className='page-inner-container' style={{ backgroundColor: 'white' }}>
                    <div className='edit-container'>
                        <div className='edit-row'>
                            <label>Full name *: </label>
                            <input className='edit-input' type="text" value={name}
                                onChange={e => setName(e.target.value)} />
                        </div>
                        <div className='edit-row'>
                            <label>Phone *: </label>
                            <input className='edit-input' type="number" value={phone}
                                maxLength={11} onChange={e => setPhone(e.target.value)} />
                        </div>
                        <div className='edit-row'>
                            <label>City: </label>
                            <input className='edit-input' type="text" value={city}
                                onChange={e => setCity(e.target.value)} />
                        </div>
                        <div className='edit-row'>
                            <label>Postal Code: </label>
                            <input className='edit-input' type="number" value={postalCode}
                                onChange={e => setPostalCode(e.target.value)} />
                        </div>
                        <div className='edit-row'>
                            <label>Address: </label>
                            <div className='edit-input' >
                                <textarea className='text-area' type="text" value={address}
                                    rows={4} onChange={(e) => setAddress(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <button className='button-submit' onClick={handleUpdate}>Update</button>
                </div>

            </div>
        </div>
    );
}

export default EditCustomer;