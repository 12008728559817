import { useState, useEffect } from "react"

const useFetch = (url) => {
    const [data, setData] = useState(null)
    const [isPending, setIsPending] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {
        setIsPending(true)
        setData(null)
        // console.log(url);
        const abortCont = new AbortController()

        const request = async () => {
            fetch(url, {
                method: 'GET',
                signal: abortCont.signal
            })
                .then(res => {
                    // console.log(res.json());
                    if (!res.ok) {
                        throw Error('could not fetch the data for that resource')
                    }
                    return res.json()
                })
                .then((resObj) => {
                    // console.log(resObj);
                    setIsPending(false)
                    if(resObj.status_code!==200){ // if error on response
                        setError(resObj.message)
                        return
                    }
                    setError(null)
                    setData(resObj.data)
                })
                .catch(err => {
                    if (err.name === 'AbortError')
                        return //aborted
                    setIsPending(false)
                    setError(err.message)
                })
        }

        request()

        return () => abortCont.abort()
    }, [url])

    return { data, isPending, error }
}

export default useFetch