import './loader.css'

const Loader = ({show}) => {
    return (
        <div className='full-screen-container'  style={!show ?{ display: 'none' }:{}}>
            <div className="loader"></div>
        </div>
    );
}

export default Loader;