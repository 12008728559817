import React, { useRef, useState } from 'react'
import Loader from '../loader/Loader'
import './uploadapk.css'
import { Post } from '../../networking/Post'
import { URL_API } from '../../values/AppConstants'
import { useSelector } from 'react-redux'
import axios from 'axios'

export default function UploadApk() {
    const [isLoading, setIsLoading] = useState(false)
    const [selectedFile, setSeletedFile] = useState(null)
    const [version, setVersion] = useState('')
    const input = useRef()
    const { login } = useSelector(state => state.login)
    const [progress, setProgress] = useState(0)
    const [showRemaining, setShowRemaining] = useState(null)

    const handleFileClick = (e) => {
        const file = e.target.files[0]
        setSeletedFile(file)
    }

    const resetValues = () => {
        input.current.value = ''
        setVersion('')
        setSeletedFile(null)
    }

    const handleUpload = async () => {
        if (selectedFile == null || version == '') {
            return alert('Please enter all fileds')
        }
        setIsLoading(true)
        setShowRemaining(true)
        const formData = new FormData()
        formData.append('version', version)
        formData.append('apk', selectedFile)
        // const postData = {
        //     url: URL_API.createApk,
        //     token: login.token,
        //     body: formData
        // }
        // Post(postData, response)
        axios.post(URL_API.createApk, formData, {
            headers: {
                'Authorization': 'Bearer ' + login.token
            },
            onUploadProgress: data => {
                const p = Math.round((100 * data.loaded) / data.total)
                // console.log(p);
                setProgress(p)
            }
        })
            .then(response => {
                // console.log(response)
                const data = response.data
                setIsLoading(false)
                setShowRemaining(false)
                if (data.status_code === 200) {
                    resetValues()
                    alert(response.data.message)
                }
                else{
                    throw Error(data.message)
                }
            })
            .catch(error => {
                setIsLoading(false)
                setShowRemaining(false)
                alert(error)
                // console.log(error);
            })
    }

    const response = (dataObj, error) => {
        setIsLoading(false)
        if (error) {
            alert(error)
            return
        }
        resetValues()
        alert(dataObj.message)
    }

    return (
        <div className="container">
            <Loader show={isLoading} />
            <div className='remainig-container' style={showRemaining ? {} : { display: 'none' }}>
                <p>{progress}%</p>
            </div>
            <div className='page-container'>
                <h1 className="page-title">Upload Apk</h1>
                <div className='page-inner-container'>
                    <div className='upload-container'>
                        <input type="file" onChange={handleFileClick} ref={input} />
                        <label>Version: </label>
                        <input type="text" value={version} onChange={(e) => setVersion(e.target.value)}
                            className='version-input' />
                        <button className='upload-btn' onClick={handleUpload}>
                            Upload
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
