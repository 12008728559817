import './fullimage.css'
import { MdClose } from 'react-icons/md'
import FailedImage from '../../assets/no_image.png'

const FullImage = ({ show, url, close }) => {
    const imageLoadFailed = ({ target }) => {
        target.onerror = null; // prevents looping
        target.src = FailedImage
    }
    return (
        <div className='full-screen-container' style={!show ?{ display: 'none' }:{}}>
            <img className='full-image' src={url} alt="product" onError={imageLoadFailed}/>
            <button className="close-image" onClick={close}>
                <MdClose style={{ fill: 'black' }} fontSize={25} />
            </button>
        </div>
    );
}

export default FullImage;