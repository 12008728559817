import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import store from './redux/store';
import { Provider } from 'react-redux';
import ViewProduct from './components/products/viewproduct/ViewProduct';
import AddProduct from './components/products/addproduct/AddProduct'
import EditProduct from './components/products/editproduct/EditProduct';
import Prescription from './components/prescription/Prescription';
import Orders from './components/order/Orders';
import Demand from './components/demand/Demand';
import ViewCustomers from './components/customers/ViewCustomers';
import AddCustomer from './components/customers/addcustomer/AddCustomer';
import EditCustomer from './components/customers/editcustomer/EditCustomer';
import Banners from './components/banners/Banners';
import OrderDetails from './components/order/orderdetails/OrderDetails';
import UploadImages from './components/products/uploadimages/UploadImages';
import { hydrate } from './redux/login';
import EditBanner from './components/banners/editbanner/EditBanner';
import AddBanner from './components/banners/addbanner/AddBanner';
import UploadApk from './components/uploadapk/UploadApk';

//disabling logs
if (process.env.NODE_ENV !== "development")
  console.log = () => { };
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')
//saving data on state change
store.subscribe(() => {
  try {
    localStorage.setItem('login', JSON.stringify(store.getState().login.login))
  }
  catch (e) {

  }
})
//getting data for initial state from local storage
const getLoginFromLocal = () => {
  try {
    const data = localStorage.getItem('login')
    if (data) {
      return JSON.parse(data)
    }
    else {
      return null
    }
  }
  catch (e) {
    return null
  }
}

const login = getLoginFromLocal()
if (login) {
  // console.log(login);
  store.dispatch(hydrate(login))
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename={baseUrl}>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<ViewProduct />} />
          <Route path='/add-product' element={<AddProduct />} />
          <Route path='/edit-product/:id' element={<EditProduct />} />
          <Route path='/upload-images' element={<UploadImages />} />
          <Route path='/prescriptions' element={<Prescription />} />
          <Route path='/orders' element={<Orders />} />
          <Route path='/demands' element={<Demand />} />
          <Route path='/customers' element={<ViewCustomers />} />
          <Route path='/add-customer' element={<AddCustomer />} />
          <Route path='/edit-customer/:id' element={<EditCustomer />} />
          <Route path='/banners' element={<Banners />} />
          <Route path='/edit-banner' element={<EditBanner />} />
          <Route path='/add-banner' element={<AddBanner />} />
          <Route path='/order-details' element={<OrderDetails />} />
          <Route path='/upload-apk' element={<UploadApk />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </Provider>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
