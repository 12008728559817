import { Link } from "react-router-dom";
import { MdExpandMore } from "react-icons/md";
import './header.css'

const productsExpandData = [
    { name: 'View Products', link: '/' },
    { name: 'Add Product', link: '/add-product' },
    { name: 'Upload Images', link: '/upload-images' },
    { name: 'Upload Apk', link: '/upload-apk' }
]
const customerExpandData = [
    { name: 'View Customers', link: '/customers' },
    { name: 'Add Customer', link: '/add-customer' }
]
const Header = () => {
    return (
        <header className="container">
            <nav className="nav-bar">
                <div className="drop-down">
                    <Link className="nav-link" to='/'>
                        <span className="nav-name">Products</span>
                        <MdExpandMore style={{ fill: '#fff' }} />
                    </Link>
                    <Expand data={productsExpandData} />
                </div>
                <Link className="nav-link" to='/prescriptions'>
                    <span className='nav-name'>Prescriptions</span>
                    {/* <MdExpandMore /> */}
                    {/* <Expand /> */}
                </Link>
                <Link className="nav-link" to='/orders'>
                    <span className='nav-name'>Orders</span>
                    {/* <MdExpandMore /> */}
                </Link>
                <Link className="nav-link" to='/demands'>
                    <span className='nav-name'>Demands</span>
                    {/* <MdExpandMore /> */}
                </Link>
                <div className="drop-down">
                    <Link className="nav-link" to='/customers'>
                        <span className="nav-name">Customers</span>
                        <MdExpandMore style={{ fill: '#fff' }} />
                    </Link>
                    <Expand data={customerExpandData} />
                </div>
                <Link className="nav-link" to='/banners'>
                    <span className='nav-name'>Banners</span>
                    {/* <MdExpandMore /> */}
                </Link>
            </nav>
        </header>
    );
}

export default Header;

function Expand({ data }) {
    return (
        <div className="expand-container">
            <div className="expand-sub">
                {data && data.map((p, i) => (
                    <Link className="expand-name" to={p.link} key={i}>{p.name}</Link>
                ))
                }
            </div>
        </div>
    )
}