import { useRef, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { Post } from '../../../networking/Post'
import { URL_API } from '../../../values/AppConstants'
import Loader from '../../loader/Loader'

const AddBanner = () => {
    const [selectedFile, setSeletedFile] = useState(null)
    const [imageUrl, setImageUrl] = useState(null)
    const [url, setUrl] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const { login } = useSelector(state => state.login)
    const input = useRef()

    const resetValues = () => {
        input.current.value = ''
        setImageUrl(null)
        setSeletedFile(null)
        setUrl('')
    }

    const handleFileClick = (e) => {
        const file = e.target.files[0]
        setSeletedFile(file)
        setImageUrl(URL.createObjectURL(file))
    }
    const handleAdd = async () => {
        if (imageUrl == null) {
            return alert('Please select image')
        }
        setIsLoading(true)
        const formData = new FormData()
        formData.append('url', url)
        formData.append('photo', selectedFile)
        const postData = {
            url: URL_API.createBanner,
            token: login.token,
            body: formData
        }
        Post(postData, response)
    }

    const response = (dataObj, error) => {
        setIsLoading(false)
        if (error) {
            alert(error)
            return
        }
        resetValues()
        alert(dataObj.message)
    }

    return (
        <div className="container">
            <Loader show={isLoading} />
            <div className='page-container'>
                <h1 className="page-title">Add Banner</h1>
                <div className='page-inner-container'>
                    <div className="ab-container">
                        <input type="file" onChange={handleFileClick} ref={input} />
                        {imageUrl && <img src={imageUrl} alt="banner" className='selected-b-img' />}
                        <div>
                            <label>Url: </label>
                            <input type="text" value={url} onChange={(e) => setUrl(e.target.value)}
                                style={{ padding: '.3rem .5rem' }} />
                        </div>
                        <button className='ab-button' onClick={handleAdd}>
                            Add
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddBanner;