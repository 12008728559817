import { useEffect, useRef, useState } from 'react';
import { MdOutlineCloudUpload } from 'react-icons/md'
import { URL_API, CATEGORY, SUB_CATEGORY } from '../../../values/AppConstants';
import Loader from '../../loader/Loader';
import failedImage from '../../../assets/no_image_small.png'
import { Post } from '../../../networking/Post';
import { useSelector } from 'react-redux';

const AddProduct = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [name, setName] = useState('')
    const [brand, setBrand] = useState('')
    const [sault, setSault] = useState('')
    const [description, setDescription] = useState('')
    const [price, setPrice] = useState('')
    const [category, setCategory] = useState(CATEGORY[0].name)
    const [subCategory, setSubCategory] = useState(SUB_CATEGORY[0].name)
    const [photo, setPhoto] = useState('')
    const fileInput = useRef()
    // for uploading new file
    const [selectedFile, setSeletedFile] = useState('')
    const { login } = useSelector(state => state.login)

    const imageLoadFailed = ({ target }) => {
        target.onerror = null; // prevents looping
        target.src = failedImage
    }

    const openFileSelector = () => {
        fileInput.current.click()
    }
    const handleFileClick = (e) => {
        const file = e.target.files[0]
        setSeletedFile(file)
        setPhoto(URL.createObjectURL(file))
    }

    const handleAdd = async () => {
        if (name === '' || price === '' || category === '') {
            return alert('Please enter required fields')
        }
        setIsLoading(true)
        const url = URL_API.createProduct
        const formData = new FormData()
        formData.append('name', name)
        formData.append('brand', brand)
        formData.append('sault', sault)
        formData.append('description', description)
        formData.append('price', price)
        formData.append('category', category)
        if (selectedFile) {
            formData.append('photo', selectedFile, photo)
        }
        const postData = {
            url: url,
            token: login.token,
            body: formData
        }
        Post(postData, response)
    }

    const response = (dataObj, error) => {
        setIsLoading(false)
        if (error) {
            alert(error)
            return
        }
        alert(dataObj.message)
        resetData()
    }
    const resetData = () => {
        setName('')
        setBrand('')
        setSault('')
        setDescription('')
        setPrice('')
        setPhoto('')
    }
    return (
        <div className="container">
            <Loader show={isLoading} />
            <div className='page-container'>
                <h1 className="page-title">Add Product</h1>
                {/* form */}
                <div className='page-inner-container' style={{ backgroundColor: 'white' }}>
                    <div className='edit-container'>
                        <div className='edit-row'>
                            <label>Name *: </label>
                            <input className='edit-input' type="text" value={name}
                                onChange={e => setName(e.target.value)} />
                        </div>
                        <div className='edit-row'>
                            <label>Brand: </label>
                            <input className='edit-input' type="text" value={brand}
                                onChange={e => setBrand(e.target.value)} />
                        </div>
                        <div className='edit-row'>
                            <label>Sault: </label>
                            <input className='edit-input' type="text" value={sault}
                                onChange={e => setSault(e.target.value)} />
                        </div>
                        <div className='edit-row'>
                            <label>Description: </label>
                            <div className='edit-input' >
                                <textarea className='text-area' type="text" value={description}
                                    rows={4} onChange={(e) => setDescription(e.target.value)} />
                            </div>
                        </div>
                        <div className='edit-row'>
                            <label>Price *: </label>
                            <input className='edit-input' type="text" value={price}
                                onChange={e => setPrice(e.target.value)} />
                        </div>
                        <div className='edit-row'>
                            <label>Category *: </label>
                            {/* <input className='edit-input' type="text" value={category} /> */}
                            <div className='edit-input'>
                                <select value={category} onChange={(e) => setCategory(e.target.value)}>
                                    {CATEGORY.map((item, i) => (
                                        <option value={item.name} key={i}>{item.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        {/* show only when category = Cosmetics */}
                        {category === 'Surgical' &&
                            <div className='edit-row'>
                                <label>Sub Category: </label>
                                <div className='edit-input'>
                                    <select value={subCategory} onChange={(e) => setSubCategory(e.target.value)}>
                                        {SUB_CATEGORY.map((item, i) => (
                                            <option value={item.name} key={i}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        }
                        <div className='edit-row'>
                            <label>photo: </label>
                            <img className='form-image' src={photo} alt="product" onError={imageLoadFailed} />
                            <button className='upload-button' onClick={openFileSelector}>
                                <MdOutlineCloudUpload style={{ fill: '#11468F' }} size={24} />
                                <input ref={fileInput} type="file" hidden onChange={handleFileClick} />
                            </button>
                        </div>
                    </div>
                    <button className='button-submit' onClick={handleAdd}>Add</button>
                </div>

            </div>
        </div>
    );
}

export default AddProduct;