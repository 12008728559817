import { createSlice } from "@reduxjs/toolkit"
export const loginSlice = createSlice({
    name: 'login',
    initialState: {
        login: {
            isLoggedIn: false,
        },
    },
    reducers: {
        hydrate: (state, action) => {
            state.login = action.payload
        },
        saveLogin: (state, action) => {
            state.login = action.payload
            state.login.isLoggedIn = true
        },
        removeLogin: (state) => {
            state.login.isLoggedIn = false
        }
    }
})

export const { saveLogin, hydrate, removeLogin } = loginSlice.actions
export default loginSlice.reducer
