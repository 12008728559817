import { useState } from 'react';
import './login.css'
import { URL_API } from '../../values/AppConstants';
import Loader from '../loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { saveLogin } from '../../redux/login';

const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()

    const handleLogin = async () => {
        if (email === '' || password === '') {
            return alert('Enter all fields')
        }
        setIsLoading(true)
        const formData = new FormData()
        formData.append('email', email)
        formData.append('password', password)
        fetch(URL_API.login, {
            method: 'POST',
            body: formData
        })
            .then(res => {
                if (res.ok) {
                    return res.json()
                }
                throw Error(res.statusText)
            })
            .then(dataObj => {
                // console.log(dataObj);
                setIsLoading(false)
                dispatch(saveLogin(dataObj.data))
            })
            .catch(e => {
                setIsLoading(false)
                alert(e)
            })
    }
    return (
        <div className="loginContainer">
            <Loader show={isLoading}/>
            <div className='loginForm'>
                <h1 style={{ marginBottom: '1rem' }}>Admin Login</h1>
                <input className='loginInput' type="email" placeholder='Enter Email'
                    onChange={(e) => setEmail(e.target.value)} />
                <input className='loginInput' type="password" placeholder='Enter Passowrd'
                    onChange={(e) => setPassword(e.target.value)} />
                <button className='loginButton loginInput' onClick={handleLogin}>Login</button>
            </div>
        </div>
    );
}

export default Login;