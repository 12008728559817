import { useSelector } from "react-redux"

export const Post = async (data, response) => {
    const mHeaders = new Headers()
    mHeaders.append('Authorization', 'Bearer ' + data.token)
    fetch(data.url, {
        method: 'POST',
        headers: mHeaders,
        body: data.body
    })
        .then(res => {
            return res.json()
        })
        .then((resObj) => {
            if (resObj.status_code !== 200) {
                const errorDetails = resObj.error_details
                throw JSON.stringify(errorDetails)
            }
            response(resObj, null)
        })
        .catch(err => {
            // console.log(err);
            // if (err.name === 'AbortError')
            //     return //aborted
            // setIsPending(false)
            // setError(err.message)
            response(null, err)
        })
    // return { data, isPending, error }
}