import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Post } from '../../../networking/Post';
import { URL_API } from '../../../values/AppConstants';
import Loader from '../../loader/Loader';
import './uploadimages.css'

const UploadImages = () => {
    const [isLoading, setIsLoading] = useState(false)
    const { login } = useSelector(state => state.login)
    const [selectedFiles, setSelectedFiles] = useState([])
    const [showRemaining, setShowRemaining] = useState(null)
    const fileInput = useRef()

    const handleFilesSeleted = (e) => {
        const files = e.target.files
        setSelectedFiles(Array.from(files))
        // setImageUrl(URL.createObjectURL(file))
    }
    const uploadFiles = async () => {
        if (selectedFiles === null) {
            return alert('Please select pictures')
        }
        setIsLoading(true)
        const url = URL_API.uploadImages
        const formData = new FormData()
        // for (let index = 0; index < selectedFiles.length; index++) {
        //     formData.append('files[]', selectedFiles[index])

        // }
        const totalFiles = selectedFiles.length
        var limit = totalFiles >= 10 ? 10 : totalFiles;
        for (let index = 0; index < limit; index++) {
            formData.append('files[]', selectedFiles[index])
        }
        // removing files from selected
        var filesLeft = selectedFiles.splice(0, limit)
        setSelectedFiles(filesLeft)
        // selectedFiles.forEach(element => {
        // });
        const mHeaders = new Headers()
        mHeaders.append('Authorization', 'Bearer ' + login.token)
        fetch(url, {
            method: 'POST',
            headers: mHeaders,
            body: formData
        })
            .then(res => {
                // console.log(res.json());
                if (!res.ok) {
                    throw Error('could not fetch the data for that resource')
                }
                return res.json()
            })
            .then((resObj) => {
                // console.log(resObj);
                if (selectedFiles.length === 0) {
                    setIsLoading(false)
                    fileInput.current.value = ''
                    setSelectedFiles(null)
                    alert(resObj.message)
                    setShowRemaining(null)
                }
                else {
                    setShowRemaining(selectedFiles.length)
                    // setTimeout(() => {
                    //     setShowRemaining(null)
                    // }, 3000);
                    uploadFiles()
                }
            })
            .catch(err => {
                // if (err.name === 'AbortError')
                //     return //aborted
                // setIsPending(false)
                // setError(err.message)
                console.log(err);
                setIsLoading(false)
                alert(err.message)
            })
    }

    return (
        <div className="container">
            <Loader show={isLoading} />
            {/* remaining images */}
            <div className='remainig-container' style={showRemaining ? {} : { display: 'none' }}>
                <p>{showRemaining} images are remaining to upload</p>
            </div>
            <div className='page-container'>
                <h1 className="page-title">Upload Images</h1>
                <div className='page-inner-container'>
                    <input className='upload-select' ref={fileInput}
                        type="file" multiple onChange={handleFilesSeleted} />
                    <button className='upload-btn' onClick={uploadFiles}>Upload</button>
                </div>
            </div>

        </div>
    );
}

export default UploadImages;