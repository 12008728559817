import { useState } from "react";
import { useLocation } from "react-router-dom";
import ProductView from "../../productview/ProductView";
import './orderdetails.css'

const OrderDetails = () => {
    const location = useLocation()
    const { data } = location.state
    const [showProduct, setShowProduct] = useState({ show: false, id: '' })

    const TableRow = ({ item }) => {
        return (
            <tr className='table-row'>
                {/* <td>{item.id}</td>
                <td>{item.phone_number}</td> */}
                <td className='table-item'>
                    <p className="order-product-name"
                        onClick={() => setShowProduct({ show: true, id: item.product_id })}>
                        {item.product_name}
                    </p>
                </td>
                <td>
                    <p >{item.quantity}</p>
                </td>
                <td>
                    <p >{item.price}</p>
                </td>
                <td>
                    <p >{item.total}</p>
                </td>
            </tr>
        )
    }

    return (
        <div className="container">
            {/* show product on click */}
            {showProduct.show && <ProductView id={showProduct.id}
                close={() => setShowProduct({ show: false })} />}
            <div className='page-container'>
                <h1 className="page-title">Order Details</h1>
                <div className='page-inner-container'>
                    <div className="order-details">
                        <div className="order-details-row">
                            <p className="order-label">Order Id</p>
                            <p className="order-content">{data.id}</p>
                        </div>
                        <div className="order-details-row">
                            <p className="order-label">Phone Number</p>
                            <p className="order-content">{data.phone_number}</p>
                        </div>
                        <div className="order-details-row">
                            <p className="order-label">Date</p>
                            <p className="order-content">{data.created_at}</p>
                        </div>
                        <div className="order-details-row">
                            <p className="order-label">Address</p>
                            <p className="order-content">{data.address}</p>
                        </div>
                        <div className="order-details-row">
                            <p className="order-label">Location</p>
                            <p className="order-content">{data.location}</p>
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th style={{ textAlign: 'start' }}>Product Name</th>
                                <th>Quantity</th>
                                <th>Price</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.order_details.map((item, i) => <TableRow item={item} key={item.id} />)}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default OrderDetails;