import { useState } from 'react'
import { URL_API } from '../../values/AppConstants'
import { FiEdit } from 'react-icons/fi'
import { MdDelete } from 'react-icons/md'
import Loader from '../loader/Loader'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Delete } from '../../networking/Delete'

var controller = new AbortController()
var searchTimer = null
const ViewCustomers = () => {
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const { login } = useSelector(state => state.login)

    const handleQueryChange = (event) => {
        const val = event.target.value
        if (searchTimer != null) {
            clearTimeout(searchTimer)
            searchTimer = null
        }
        searchTimer = setTimeout(() => {
            resetData()
            getList(val)
        }, 500)
    }

    const resetData = () => {
        controller.abort()
        // console.log('aborted')
        controller = new AbortController()
        setData(null)
    }

    const getList = async (query) => {
        if (query == '') {
            return
        }
        setIsLoading(true)
        const url = URL_API.getAllCustomers + '?search=' + query
        const mHeader = new Headers()
        mHeader.append('Authorization', 'Bearer ' + login.token)
        fetch(url, {
            method: 'GET',
            headers: mHeader,
            signal: controller.signal
        })
            .then(res => {
                if (!res.ok) {
                    throw Error('could not fetch the data for that resource')
                }
                return res.json()
            })
            .then((res) => {
                setIsLoading(false)
                // console.log(res);
                const dataObj = res.data
                if (res.data.length === 0) {
                    return setData(null)
                }
                setData(dataObj)
            })
            .catch(err => {
                // console.log(err);
                setIsLoading(false)
            })
    }

    const handleDelete = async (id) => {
        setIsLoading(true)
        const postData = {
            url: URL_API.deleteCustomer + id,
            token: login.token
        }
        Delete(postData, responseDelete)
    }

    const responseDelete = (dataObj, error) => {
        setIsLoading(false)
        const filter = data.filter(item => item.id !== dataObj.id)
        setData(filter)
        if (error) {
            alert(error)
            return
        }
        alert(dataObj.message)
    }
    const TableRow = ({ item }) => {

        return (
            <tr className='table-row'>
                <td>{item.id}</td>
                <td>{item.full_name}</td>
                <td>{item.phone_number}</td>
                <td>{item.city}</td>
                <td>{item.postal_code}</td>
                <td className="table-item">
                    <p style={{width:'30ch'}}>{item.address}</p>
                </td>
                <td >
                    <div className='table-action'>
                        <Link className='table-action-button' to={`/edit-customer/${item.id}`}>
                            <FiEdit size={20} />
                        </Link>
                        <button className='table-action-button' onClick={() => handleDelete(item.id)}>
                            <MdDelete style={{ fill: 'red' }} size={20} />
                        </button>
                    </div>
                </td>
            </tr>
        )
    }
    return (
        <div className="container">
            <Loader show={isLoading} />
            <div className='page-container'>
                <h1 className="page-title">View Customers</h1>
                <div className='page-inner-container'>
                    <input type="text" placeholder='Enter number to search'
                        className='search-input' onChange={handleQueryChange} />
                    {data &&
                        // <div>
                        <table>
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Full Name</th>
                                    <th>Phone</th>
                                    <th>City</th>
                                    <th>Postal Code</th>
                                    <th style={{textAlign:'start'}}>Address</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, i) => <TableRow item={item} key={item.id} />)}
                            </tbody>
                        </table>
                        // </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default ViewCustomers;