import { useState } from 'react'
import { URL_API } from '../../values/AppConstants'
import Loader from '../loader/Loader'
import { Link } from 'react-router-dom'
import useFetchPaginated from '../../networking/useFetchPaginated'
import { useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import LoaderInLine from '../loader/LoaderInline'
import { Delete } from '../../networking/Delete'

const Orders = () => {
    const [page, setPage] = useState(1)
    const { data, isPending, error, hasMore, updateData } = useFetchPaginated(URL_API.getOrders + page)
    const [isLoading, setIsLoading] = useState(false)
    const { login } = useSelector(state => state.login)


    const handleCompleted = async (id) => {
        //change url after building api
        setIsLoading(true)
        const postData = {
            url: URL_API.deleteOrder + id,
            token: login.token
        }
        Delete(postData, responseDelete)
    }

    const responseDelete = (dataObj, error) => {
        setIsLoading(false)
        if (error) {
            alert(error)
            return
        }
        updateData(dataObj.id)
        alert(dataObj.message)
    }

    const fetchMore = () => {
        setPage(p => p + 1)
    }

    const TableRow = ({ item }) => {
        return (
            <tr className='table-row'>
                <td>{item.id}</td>
                <td>{item.phone_number}</td>
                <td>
                    <p className='table-date'>{item.created_at}</p>
                </td>
                <td>
                    <Link className='view-details' to='/order-details' state={{ data: item }}>
                        View Details
                    </Link>
                </td>
                <td >
                    <button className='table-action-complete'
                        onClick={() => handleCompleted(item.id)}>Complete</button>
                </td>
            </tr>
        )
    }

    return (
        <div className="container">
            <Loader show={(page === 1 && isPending) || isLoading} />
            <div className='page-container'>
                <h1 className="page-title">Orders</h1>
                <div className='page-inner-container'>
                    {data &&
                        <InfiniteScroll
                            dataLength={data.length}
                            next={fetchMore}
                            hasMore={hasMore}
                            loader={<LoaderInLine show={isPending} />}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Phone</th>
                                        <th>Date</th>
                                        <th>Details</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, i) => <TableRow item={item} key={item.id} />)}
                                </tbody>
                            </table>
                        </InfiniteScroll>
                    }
                </div>
            </div>
        </div>
    );
}

export default Orders;