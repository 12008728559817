// const BASE_URL = 'http://127.0.0.1:8000/api'
const BASE_URL = 'https://api.pharmacyonlinedh.com/api'

export const PAGE_SIZE = 20
// status codes
export const SUCCESS_CODE = 200
export const ORDER_SUCCESS_CODE = 201
export const URL_API = {
    // images_base: 'http://127.0.0.1:8000',
    images_base: 'https://api.pharmacyonlinedh.com',
    //products
    getSingelProduct: BASE_URL + '/get-single-product?id=',
    updateProduct: BASE_URL + '/update-product?id=',
    createProduct: BASE_URL + '/create-product',
    deleteProduct: BASE_URL + '/delete-product?id=',
    search: BASE_URL + '/get-products',
    uploadImages: BASE_URL + '/upload-images',
    //prescription
    getPrescription: BASE_URL + '/get-prescriptions?page=',
    deletePrescription: BASE_URL + '/delete-prescription?id=',
    //order
    getOrders: BASE_URL + '/get-all-orders?page=',
    deleteOrder: BASE_URL + '/delete-order?id=',
    //demand
    getDemand: BASE_URL + '/get-demands?page=',
    deleteDemand: BASE_URL + '/delete-demand?id=',
    //customer
    getAllCustomers: BASE_URL + '/get-all-customers',
    createCustomers: BASE_URL + '/create-customer',
    getSingleCustomer: BASE_URL + '/get-single-customer-to-edit?id=',
    updateCustomers: BASE_URL + '/update-customer?id=',
    deleteCustomer: BASE_URL + '/delete-customer?id=',
    //admin
    login: BASE_URL + '/login',
    // banner
    createBanner: BASE_URL + '/create-sliding-banner',
    getHome: BASE_URL + '/home',
    updateBanner: BASE_URL + '/update-banner?id=',
    deleteBanner: BASE_URL + '/delete-banner?id=',
    //apk
    createApk: BASE_URL + '/create-apk',
}

/* category list */
export const CATEGORY = [
    { name: 'Medicines' },
    { name: 'Surgical' },
    // { name: 'Surgical Goods' },
    // { name: 'Cosmetics' },
]

/* sub category list */
// export const SUB_CATEGORY = [
//     { name: 'baby care' },
//     { name: 'oral care' },
//     { name: 'skin care' },
//     { name: 'bath & soap' },
//     { name: 'personal care' },
//     { name: "men's care" },
//     { name: "hair care" },
//     { name: "fragrances" },
//     { name: "makeup" },
// ]
export const SUB_CATEGORY = [
    { name: 'general' },
    { name: 'bandages' },
    { name: 'anesthesia' },
    { name: 'laproscopic' },
    { name: 'cardiac' },
    { name: "icu care" },
    { name: "dialysis" },
    { name: "sutures" },
    { name: "other" },
]

