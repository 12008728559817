
export const Delete = async (data,response) => {
    const mHeaders = new Headers()
    mHeaders.append('Authorization', 'Bearer ' + data.token)
    fetch(data.url, {
        method: 'DELETE',
        headers: mHeaders
    })
        .then(res => {
            // console.log(res.json());
            if (!res.ok) {
                throw Error('Something went wrong please try again')
            }
            return res.json()
        })
        .then((resObj) => {
            // console.log(resObj);
            response(resObj, null)
        })
        .catch(err => {
            // if (err.name === 'AbortError')
            //     return //aborted
            // setIsPending(false)
            // setError(err.message)
            response(null, err.message)
        })
    // return { data, isPending, error }
}