import { useState, useEffect } from "react"
import { useSelector } from 'react-redux'
import { PAGE_SIZE } from "../values/AppConstants"

const useFetchPaginated = (url) => {
    const [data, setData] = useState(null)
    const [isPending, setIsPending] = useState(true)
    const [error, setError] = useState(null)
    const [hasMore, setHasMore] = useState(true)
    const { login } = useSelector(state => state.login)

    useEffect(() => {
        setIsPending(true)
        // console.log(url);
        const abortCont = new AbortController()
        const mHeaders = new Headers()
        mHeaders.append('Authorization', 'Bearer ' + login.token)

        const request = async () => {
            fetch(url, {
                method: 'GET',
                headers: mHeaders,
                signal: abortCont.signal
            })
                .then(res => {
                    if (!res.ok) {
                        throw Error('could not fetch the data for that resource')
                    }
                    return res.json()
                })
                .then((resObj) => {
                    setIsPending(false)
                    setError(null)
                    const dataObj = resObj.data
                    // console.log(dataObj);
                    setHasMore(dataObj.length === PAGE_SIZE)
                    if (data == null) {
                        if (dataObj.length === 0) {
                            setData(null)
                        }
                        else {
                            setData(dataObj)
                        }
                    }
                    else {
                        setData(prev => [...prev, ...dataObj])
                    }
                })
                .catch(err => {
                    if (err.name === 'AbortError')
                        return //aborted
                    setIsPending(false)
                    setError(err.message)
                })
        }

        request()

        return () => abortCont.abort()
    }, [url])

    const updateData = (id) => {
        const filter = data.filter(item => item.id !== id)
        setData(filter)
    }
    return { data, isPending, error, hasMore, updateData }
}

export default useFetchPaginated