import { useState } from 'react'
import { URL_API } from '../../values/AppConstants'
import failedImage from '../../assets/no_image_small.png'
import Loader from '../loader/Loader'
import { Link } from 'react-router-dom'
import useFetchPaginated from '../../networking/useFetchPaginated'
import { useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import FullImage from '../fullimage/FullImage'
import LoaderInLine from '../loader/LoaderInline'
import './prescription.css'
import ViewPrescription from './viewprescription/ViewPrescription'
import { Delete } from '../../networking/Delete'

const Prescription = () => {
    // const [isLoading, setIsLoading] = useState(false)
    const [page, setPage] = useState(1)
    const { data, isPending, updateData, hasMore } = useFetchPaginated(URL_API.getPrescription + page)
    const { login } = useSelector(state => state.login)
    const [showImage, setShowImage] = useState({ show: false, url: '' })
    const [isLoading, setIsLoading] = useState(false)
    // const [showPrescription, setShowPrescription] = useState({ show: false, data: null })


    // const handleCompleted = async (id) => {
    //     //change url after building api
    //     setIsLoading(true)
    //     const postData = {
    //         url: URL_API.deleteProduct + id,
    //         token: login.token
    //     }
    //     Delete(postData, responseDelete)
    // }

    // const responseDelete = (dataObj, error) => {
    //     setIsLoading(false)
    //     const filter = data.filter(item => item.id !== dataObj.id)
    //     setData(filter)
    //     if (error) {
    //         alert(error)
    //         return
    //     }
    //     alert(dataObj.message)
    // }

    const fetchMore = () => {
        setPage(p => p + 1)
    }

    const TableRow = ({ item }) => {
        const imageLoadFailed = ({ target }) => {
            target.onerror = null; // prevents looping
            target.src = failedImage
        }
        const openImage = () => {
            setShowImage({
                show: true,
                url: URL_API.images_base + item.photo
            })
        }
        // const viewPrescription = () => {
        //     setShowPrescription({ show: true, data: item })
        // }
        const handleCompleted = () => {
            setIsLoading(true)
            const postData = {
                url: URL_API.deletePrescription + item.id,
                token: login.token
            }
            Delete(postData, responseDelete)
        }

        const responseDelete = (dataObj, error) => {
            setIsLoading(false)
            if (error) {
                alert(error)
                return
            }
            updateData(dataObj.id)
            alert(dataObj.message)
        }
        return (
            <tr className='table-row'>
                <td>{item.id}</td>
                <td className="table-item">
                    <div>
                        <img className="table-image" src={URL_API.images_base + item.photo_small} alt="product"
                            onError={imageLoadFailed} onClick={openImage} />
                        <p className="item-name">{item.comment}</p>
                    </div>
                </td>
                <td>{item.phone_number}</td>
                <td>{item.days}</td>
                <td className='item-details'>{item.address}</td>
                <td>{item.location}</td>
                <td>
                    <p className='table-date'>{item.created_at}</p>
                </td>
                <td >
                    {/* <div className='table-action'> */}
                        <button className='table-action-complete'
                            onClick={handleCompleted}>Complete</button>
                    {/* </div> */}
                </td>
            </tr>
        )
    }

    return (
        <div className="container">
            <Loader show={(page === 1 && isPending) || isLoading} />
            <FullImage show={showImage.show} url={showImage.url} close={() => setShowImage({ show: false })} />
            {/* <ViewPrescription show={showPrescription.show} data={showPrescription.data} close={() => setShowPrescription({ show: false })} /> */}
            <div className='page-container'>
                <h1 className="page-title">Prescriptions</h1>
                <div className='page-inner-container'>
                    {data &&
                        <InfiniteScroll
                            dataLength={data.length}
                            next={fetchMore}
                            hasMore={hasMore}
                            loader={<LoaderInLine show={isPending} />}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Comment</th>
                                        <th>Phone</th>
                                        <th>Days</th>
                                        <th>Address</th>
                                        <th>Location</th>
                                        <th>Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, i) => <TableRow item={item} key={item.id} />)}
                                </tbody>
                            </table>
                        </InfiniteScroll>
                    }
                </div>
            </div>
        </div>
    );
}

export default Prescription;