import './App.css';
import { Outlet } from 'react-router-dom';
import { useState } from 'react';
import Login from './components/login/Login';
import Header from './components/header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { MdLogout } from "react-icons/md";
import {removeLogin} from './redux/login'

function App() {
  const { login } = useSelector(state => state.login)
  const dispatch = useDispatch()
  if (login.isLoggedIn === false) {
    return (
      <div className="App">
        <Login />
      </div>
    )
  }
  const logout = () => {
    try {
      localStorage.setItem('login', null)
    }
    catch (e) {
    }
    dispatch(removeLogin())
  }

  return (
    <div className="App">
      <MdLogout className='logout-button' onClick={logout} />
      <Header />
      <Outlet />
    </div>
  );
}

export default App;
