import useFetch from '../../networking/useFetch';
import { URL_API } from '../../values/AppConstants';
import Loader from '../loader/Loader';
import './productview.css'
import { MdClose } from 'react-icons/md'
import FailedImage from '../../assets/no_image_small.png'

const ProductView = ({ id, close }) => {
    const { data, isPending, error } = useFetch(URL_API.getSingelProduct + id)
    
    const imageLoadFailed = ({ target }) => {
        target.onerror = null; // prevents looping
        target.src = FailedImage
    }

    return (
        <div className='full-screen-container'>
            <Loader show={isPending} />
            {data &&
                <div className='product-view-container'>
                    <button className="close-image" onClick={close}>
                        <MdClose style={{ fill: 'black' }} fontSize={25} />
                    </button>
                    <h2 className='product-details-title'>Product details</h2>
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <img src={URL_API.images_base + data.photo} alt="product" 
                        onError={imageLoadFailed}/>
                        <div className='product-details'>
                            <h4 className='title'>Name</h4>
                            <p className='desc'>{data.name}</p>
                            <h4 className='title'>Brand</h4>
                            <p className='desc'>{data.brand}</p>
                            <h4 className='title'>Sault</h4>
                            <p className='desc'>{data.sault}</p>
                            <h4 className='title'>Description</h4>
                            <p className='desc'>{data.description}</p>
                            <h4 className='title'>Category</h4>
                            <p className='desc'>{data.category}</p>
                            <h4 className='title'>Price</h4>
                            <p className='desc'>{data.price}</p>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default ProductView;