import { Link } from 'react-router-dom'
import { SUCCESS_CODE, URL_API } from '../../values/AppConstants'
import { FiEdit } from 'react-icons/fi'
import { MdDelete, MdAdd } from 'react-icons/md'
import './banners.css'
import { Delete } from '../../networking/Delete'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import FullImage from '../fullimage/FullImage'
import AddBanner from './addbanner/AddBanner'
import EditBanner from './editbanner/EditBanner'
import Loader from '../loader/Loader'

const Banners = () => {
    const [isLoading, setIsLoading] = useState(false)
    const { login } = useSelector(state => state.login)
    const [data, setData] = useState(null)
    const [showImage, setShowImage] = useState({ show: false, url: '' })
    const [showEdit, setShowEdit] = useState({
        data: null,
        show: false
    })

    useEffect(() => {
        setIsLoading(true)
        fetch(URL_API.getHome)
            .then(res => res.json())
            .then(obj => {
                setIsLoading(false)
                // console.log(obj);
                if (obj.status_code !== SUCCESS_CODE) {
                    throw obj.message
                }
                setData(obj.data.sliding_banners)
            })
            .catch(e => {
                setIsLoading(false)
                alert(e)
            })
    }, [])

    const handleDelete = async (id) => {
        setIsLoading(true)
        const postData = {
            url: URL_API.deleteBanner + id,
            token: login.token
        }
        Delete(postData, responseDelete)
    }

    const responseDelete = (dataObj, error) => {
        setIsLoading(false)
        if (error) {
            alert(error)
            return
        }
        const filter = data.filter(item => item.id !== dataObj.id)
        setData(filter)
        alert(dataObj.message)
    }

    const editBanner = (banner) => {
        if (banner) {
            var newData = Object.assign([], data)
            newData = newData.map(d => d.id !== banner.id ? d : banner)
            setData(newData)
        }
    }

    return (
        <div className="container">
            <Loader show={isLoading}/>
            <FullImage show={showImage.show} url={showImage.url} close={() => setShowImage({ show: false })} />
            {/* <AddBanner show={showAdd} close={() => setShowAdd(false)} addBanner={addBanner} /> */}
            <EditBanner show={showEdit} close={() => setShowEdit({ data: null, show: false })} editBanner={editBanner} />
            <div className='page-container'>
                <h1 className="page-title">Banners</h1>
                <div className='page-inner-container'>
                    <ul className='banner-container'>
                        {data &&
                            data.map((item, i) => (<BannerView item={item} key={i}
                                setShowEdit={setShowEdit} setShowImage={setShowImage} handleDelete={handleDelete} />))
                        }
                    </ul>
                    <Link to='/add-banner' className='add-banner'>
                        <MdAdd size={40} />
                    </Link>
                </div>
            </div>
        </div>
    );
}


const BannerView = ({ item, setShowImage, setShowEdit, handleDelete }) => {
    // console.log(item);
    const openImage = () => {
        setShowImage({
            show: true,
            url: URL_API.images_base + item.photo
        })
    }
    return (
        <li>
            <div className='banner-row'>
                <img className='banner-image' src={URL_API.images_base + item.photo} alt="banner"
                    onClick={openImage} />
                <div className='table-action'>
                    <button className='table-action-button' onClick={() => setShowEdit({ data: item, show: true })}>
                        <FiEdit size={20} />
                    </button>
                    <button className='table-action-button' onClick={() => handleDelete(item.id)}>
                        <MdDelete style={{ fill: 'red' }} size={20} />
                    </button>
                </div>
            </div>
        </li>
    )
}
export default Banners;