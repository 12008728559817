import { useEffect, useRef, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { Post } from '../../../networking/Post'
import { URL_API } from '../../../values/AppConstants'
import Loader from '../../loader/Loader'

const EditBanner = ({ show, close, editBanner }) => {
    const [selectedFile, setSeletedFile] = useState(null)
    const [imageUrl, setImageUrl] = useState(null)
    const [url, setUrl] = useState('')
    const { login } = useSelector(state => state.login)
    const [isLoading, setIsLoading] = useState(false)
    const input = useRef()

    useEffect(() => {
        const data = show.data
        if (data) {
            setImageUrl(URL_API.images_base + data.photo)
            if (data.url) setUrl(data.url)
        }
    }, [show])
    const handleClose = () => {
        input.current.value = ''
        setImageUrl(null)
        setSeletedFile(null)
        setUrl('')
        close()
    }

    const handleFileClick = (e) => {
        const file = e.target.files[0]
        setSeletedFile(file)
        setImageUrl(URL.createObjectURL(file))
    }
    const handleUpdate = () => {
        if (imageUrl == null) {
            return alert('Please select image')
        }
        setIsLoading(true)
        const formData = new FormData()
        formData.append('url', url)
        if (selectedFile) {
            formData.append('photo', selectedFile)
        }
        const postData = {
            url: URL_API.updateBanner + show.data.id,
            token: login.token,
            body: formData
        }
        Post(postData, response)
    }

    const response = (dataObj, error) => {
        setIsLoading(false)
        if (error) {
            alert(error)
            return
        }
        handleClose()
        alert(dataObj.message)
        editBanner(dataObj.banner)
    }

    return (
        <div className='full-screen-container' style={!show.show ? { display: 'none' } : {}}>
            <Loader show={isLoading} />
            <div className="ab-container">
                <h2 className='ab-title'>Update Banner</h2>
                <input type="file" onChange={handleFileClick} ref={input} />
                {imageUrl && <img src={imageUrl} alt="banner" className='selected-b-img' />}
                <div>
                    <label>Url: </label>
                    <input type="text" value={url} onChange={(e) => setUrl(e.target.value)}
                        style={{ padding: '.3rem .5rem' }} />
                </div>
                <button className='ab-button' onClick={handleUpdate}>
                    Update
                </button>
                <button className="close-image" onClick={handleClose}>
                    <MdClose style={{ fill: 'black' }} fontSize={25} />
                </button>
            </div>
        </div>
    );
}

export default EditBanner;