import { useState } from 'react';
import { URL_API} from '../../../values/AppConstants';
import Loader from '../../loader/Loader';
import { Post } from '../../../networking/Post';
import { useSelector } from 'react-redux';

const AddCustomer = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [postalCode, setPostalCode] = useState('')
    // for uploading new file
    const { login } = useSelector(state => state.login)


    const handleAdd = async () => {
        if (name === '' || phone === '') {
            return alert('Please enter required fields')
        }
        setIsLoading(true)
        const url = URL_API.createCustomers
        const formData = new FormData()
        formData.append('full_name', name)
        formData.append('phone_number', phone)
        formData.append('address', address)
        formData.append('city', city)
        formData.append('postal_code', postalCode)
        const postData = {
            url: url,
            token: login.token,
            body: formData
        }
        Post(postData, response)
    }

    const response = (dataObj, error) => {
        setIsLoading(false)
        if (error) {
            alert(error)
            return
        }
        alert(dataObj.message)
        resetData()
    }
    const resetData = () => {
        setName('')
        setPhone('')
        setAddress('')
        setCity('')
        setPostalCode('')
    }
    return (
        <div className="container">
            <Loader show={isLoading} />
            <div className='page-container'>
                <h1 className="page-title">Add Customer</h1>
                {/* form */}
                <div className='page-inner-container' style={{ backgroundColor: 'white' }}>
                    <div className='edit-container'>
                        <div className='edit-row'>
                            <label>Full name *: </label>
                            <input className='edit-input' type="text" value={name}
                                onChange={e => setName(e.target.value)} />
                        </div>
                        <div className='edit-row'>
                            <label>Phone *: </label>
                            <input className='edit-input' type="number" value={phone}
                                maxLength={11} onChange={e => setPhone(e.target.value)} />
                        </div>
                        <div className='edit-row'>
                            <label>City: </label>
                            <input className='edit-input' type="text" value={city}
                                onChange={e => setCity(e.target.value)} />
                        </div>
                        <div className='edit-row'>
                            <label>Postal Code: </label>
                            <input className='edit-input' type="number" value={postalCode}
                                onChange={e => setPostalCode(e.target.value)} />
                        </div>
                        <div className='edit-row'>
                            <label>Address: </label>
                            <div className='edit-input' >
                                <textarea className='text-area' type="text" value={address}
                                    rows={4} onChange={(e) => setAddress(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <button className='button-submit' onClick={handleAdd}>Add</button>
                </div>

            </div>
        </div>
    );
}

export default AddCustomer;